import React, { useEffect, useState } from "react";
import "./JbsProject.css";
import { Row, Col, Container, Nav, Offcanvas, Image, Navbar } from "react-bootstrap";
import Projectimg from "../../assets/image 1.png";
import Projectcomp from "../../components/ProjectComp/Projectcomp";
import { Link, useLocation } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import JbsLogo from "../../assets/header/jbslogo.png";
import JbsImage from "../../assets/header/jbsimg2.png";
function JbsProject() {
  const [category, setCategory] = useState("All");
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const location = useLocation()
  return (
    <>
      <Container fluid id="project3">
      <>
        {["sm"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="mb-3"
            style={{ backgroundColor: "#fff" }}
            collapseOnSelect="true"
          >
            <Container fluid>
              <Navbar.Brand>
                <Image src={JbsLogo} id="logo1" />
                <Image src={JbsImage} id="logoimg" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to="/">
                      <Image src={JbsLogo} id="logo2" />
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link id="navItemsLink">
                    <Link
                            to="/jbs"
                            id="navItems"
                            style={
                              location.pathname === "/jbsproject"
                                ? { color: "#b6b6b6" }
                                : null
                            }
                          >
                        HOME
                        </Link>
                    </Nav.Link>
                    <Nav.Link id="navItemsLink" eventKey="2">
                    <Link
                            to="/jbs"
                            id="navItems"
                            style={
                              location.pathname === "/jbsproject"
                                ? { color: "#b6b6b6" }
                                : null
                            }
                          >
                        ABOUT US
                        </Link>
                    </Nav.Link>
                    {/* <Nav.Link id="navItemsLink" eventKey="3">
                      <AnchorLink href="#worksHomejbs" className="navItemsJbs">
                        WORKS
                      </AnchorLink>
                    </Nav.Link> */}
                    <Nav.Link id="navItemsLink" eventKey="3">
                    <Link
                            to="/jbsproject"
                            id="navItems"
                            style={
                              location.pathname === "/jbsproject"
                                ? { color: "#ff5e14", fontWeight: "bolder" }
                                : null
                            }
                          >
                        WORKS
                        </Link>
                    </Nav.Link>

                    <Nav.Link id="navItemsLink" eventKey="4">
                      <AnchorLink
                        href="#contactusHomejbs"
                        className="navItemsJbs"
                      >
                        CONTACT US
                      </AnchorLink>
                    </Nav.Link>

                    <Nav.Link id="navItemsLink" eventKey="5">
                      <Link to="/" className="navItemsJbs">
                        MAILSTONE-HOME
                      </Link>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
        <Row>
          <Col id="jbsproject2">Our Works</Col>
        </Row>
        <Container className="mb-5">
          <Row>
            {/* <Col md="3" xs="12">
              <Row
                id="project3"
                onClick={() => setCategory("All")}
                style={
                  category === "All"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                      }
                    : null
                }
              >
                All
              </Row>
              <Row
                id="project3"
                onClick={() => setCategory("Architecture")}
                style={
                  category === "Architecture"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                      }
                    : null
                }
              >
                Architecture{" "}
              </Row>
              <Row
                id="project3"
                onClick={() => setCategory("Decor")}
                style={
                  category === "Decor"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                      }
                    : null
                }
              >
                Decor
              </Row>
              <Row
                id="project3"
                onClick={() => setCategory("Outdoor")}
                style={
                  category === "Outdoor"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                        transition: "opacity 1s ease-in",
                      }
                    : null
                }
              >
                Outdoor
              </Row>
              <Row
                id="project3"
                onClick={() => setCategory("Interior")}
                style={
                  category === "Interior"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                        transition: "opacity 1s ease-in",
                      }
                    : null
                }
              >
                Interior
              </Row>
              <Row
                id="project3"
                onClick={() => setCategory("Residential")}
                style={
                  category === "Residential"
                    ? {
                        background: "#DFB946",
                        color: "#fff",
                        paddingLeft: ".4rem",
                        transition: "opacity 1s ease-in",
                      }
                    : null
                }
              >
                Residential
              </Row>
            </Col> */}
            <Col md="12" xs="12">
              {category === "All" ? <Projectcomp store="Jbs"/> : null}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default JbsProject;
