import React, { useEffect, useState,useRef } from "react";
import emailjs from '@emailjs/browser';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Nav,
  Navbar,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import jbsText from "../../assets/jbs.png";
import jbsIOne from "../../assets/Service/image35.jpeg";
import jbsITwo from "../../assets/Service/image42.jpeg";
import vdo from "../../assets/video.mp4";
import Header from "../../components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./Homejbs.css";
import Logo from "../../assets/header/logo.png";
import JbsLogo from "../../assets/header/jbslogo.png";
import JbsImage from "../../assets/header/jbsimg2.png";
import arch1 from "../../assets/Service/image43.jpeg";
import arch2 from "../../assets/Service/image45.jpeg";
import arch3 from "../../assets/Service/image80.jpeg";
import arch4 from "../../assets/Service/image47.jpeg";
import ContactUsModal from "../../components/ContactUsModal/ContactUsModal";
import Utility from "../../Toast/Utility";

export default function HomeJbs() {
  const location = useLocation();
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const [contactModal, setContactModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState('');

  const form = useRef();
  const regName = /^[a-zA-Z ]+$/;
const regEmail =
  /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;

  // ********************************VALIDATION START**********************************

  function Validation(){
      if(name.length === 0){
          Utility.eToast('Please Enter Your Name');
          return false;
      }else if(!regName.test(name)){
          Utility.eToast('Please Enter a Valid Name');
          return false;
      }else if(email.length === 0){
          Utility.eToast('Please Enter Your Email');
          return false;
      }else if(!regEmail.test(email)){
          Utility.eToast('Please Enter a Valid Email');
          return false;
      }else if(phone.length === 0 || phone.length !== 10){
        Utility.eToast('Please Enter a Valid Phone No.');
        return false;
      }else if(message.length === 0){
          Utility.eToast('Please Enter Your Message To Submit');
          return false;
      }else {
          return true;
      }
  }
  // ********************************VALIDATION END**********************************

// ************************************SEND EMAIL FUNCTION START********************************
const sendEmail = () => {
  if(Validation()){
    setLoader(true);
    console.log('.................................',form.current)
  emailjs.sendForm('service_i6kr1za', 'template_ftktpft', form.current, 'hGE1kKL1GT2OvVykY')
    .then((result) => {
        Utility.sToast('Message Sent Successfully')
        setName('')
        setEmail('')
        setMessage('')
        setPhone('')
        setLoader(false);
    }, (error) => {
        Utility.eToast(error.text)
        setLoader(false);
    });
  }
};
// ************************************SEND EMAIL FUNCTION END********************************

  return (
    <>
      <>
        {["sm"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="mb-3"
            style={{ backgroundColor: "#fff" }}
            collapseOnSelect="true"
          >
            <Container fluid>
              <Navbar.Brand>
                <Image src={JbsLogo} id="logo1" />
                <Image src={JbsImage} id="logoimg" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to="/">
                      <Image src={JbsLogo} id="logo2" />
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link id="navItemsLink">
                      <AnchorLink
                        href="#homeJbshome"
                        className="animate__animated animate__bounce navItemsJbs"
                        style={
                          location.pathname === "/jbs"
                            ? { color: "#ff5e14", fontWeight: "bolder" }
                            : null
                        }
                      >
                        HOME
                      </AnchorLink>
                    </Nav.Link>
                    <Nav.Link id="navItemsLink" eventKey="2">
                      <AnchorLink
                        href="#aboutusHomejbs"
                        className="navItemsJbs"
                      >
                        ABOUT US
                      </AnchorLink>
                    </Nav.Link>
                    {/* <Nav.Link id="navItemsLink" eventKey="3">
                      <AnchorLink href="#worksHomejbs" className="navItemsJbs">
                        WORKS
                      </AnchorLink>
                    </Nav.Link> */}
                    <Nav.Link id="navItemsLink" eventKey="3">
                    <Link
                            to="/jbsproject"
                            id="navItems"
                            style={
                              location.pathname !== "/jbsproject"
                                ? { color: "#b6b6b6", fontWeight: "bolder" }
                                : null
                            }
                          >
                        WORKS
                        </Link>
                    </Nav.Link>

                    <Nav.Link id="navItemsLink" eventKey="4">
                      <AnchorLink
                        href="#contactusHomejbs"
                        className="navItemsJbs"
                      >
                        CONTACT US
                      </AnchorLink>
                    </Nav.Link>

                    <Nav.Link id="navItemsLink" eventKey="5">
                      <Link to="/" className="navItemsJbs">
                        MAILSTONE-HOME
                      </Link>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
      <>
        {/* <Header /> */}
        <Container>
          {/* home */}
          <section id="homeJbshome">
            <div className="banner">
              <video autoPlay muted width={"100%"} loop>
                <source src={vdo} type="video/mp4" />
              </video>
              <h2>JBS</h2>
            </div>
            <Row id="Homejbs8">
              <Col>
                <div
                  className="headingTextJbs"
                  style={{
                    color: " #B6B6B6",
                    textAlign: "center",
                  }}
                >
                  INTERIORS
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4} id="Homejbs18">
                <Image src={jbsIOne} width="100%" id="Homejbs4" />
              </Col>
              <Col md={4} id="Homejbs18">
                <Image src={jbsITwo} width="100%" id="Homejbs4" />
              </Col>
              <Col md={4} id="Homejbs1">
                <div id="Homejbs9">
                  <div>We Make</div>
                  <div>Your</div>
                  <div>Home</div>
                  <div>Better</div>
                </div>
              </Col>
            </Row>
          </section>

          {/* about us */}
          <section id="aboutusHomejbs">
            <Row>
              <Col md={4} id="aboutus">
                <div id="Homejbs2">
                  <div>About</div>
                  <div>Us</div>
                </div>
              </Col>
              <Col>
                <div id="Homejbs7">
                  From The House Of MailStone Private Limited we produly
                  introduce JBS interior a one stop interior solution. MPL carry forwards its
                  expertise in Exterior, Interior and Rennovation Services,
                  being in the competition for past 12 years with its dedicated
                  and strong workforce MPL has catapaulted itself to the league
                  of serving some of the big corporates in India. Founded by
                  Mr.Tapan Kumar Panigrahi the company has grown tenfold from
                  its inception, as a determined Captain Mr. Tapan Kumar
                  Panigrahi has been successfully steering the ship through the
                  tides and Storm.Till date MPL has completed 150+ projects and
                  has been a regular name with its clients and their first
                  choice to entrust their Construction, Rennovation and
                  Maintenance Services .
                </div>
              </Col>
            </Row>
          </section>

          {/* works */}
          <section id="worksHomejbs">
            <Row>
              <Col md={4} id="Homejbs1">
                <div id="Homejbs9">
                  <div>Our work</div>
                  <div>is our</div>
                  <div>identity</div>
                </div>
              </Col>
              <Col md={8}>
                <Row id="Homejbs5">
                  <Col md={6} xs={12} id="Homejbs18">
                    <Image src={arch1} width="100%" id="Homejbs4" />
                  </Col>
                  <Col md={6} xs={12} id="Homejbs18">
                    <Image src={arch2} width="100%" id="Homejbs4" />
                  </Col>
                </Row>
                <Row id="Homejbs5">
                  <Col md={6} xs={12} id="Homejbs18">
                    <Image src={arch4} width="100%" id="Homejbs4" />
                  </Col>
                  <Col md={6} xs={12} id="Homejbs18">
                    <Image src={arch3} width="100%" id="Homejbs4" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          {/* contact us */}
          <section id="contactusHomejbs">
            <Row className="mb-5">
              <Col
                md={{ span: "4", order: "first" }}
                xs={{ span: "12", order: "last" }}
              >
                <Form ref={form}>
                  <Row>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name*"
                        id="Homejbs15"
                        name="user_name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Your Email*"
                        id="Homejbs16"
                        name="user_email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Enter Your Phone No. *"
                        id="Homejbs16"
                        name="user_phone"
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="Homejbs17"
                        placeholder="Enter Your Message*"
                        name="message"
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                </Form>
                <Button id="Homejbs14" onClick={()=>sendEmail()}>{loader ? <Spinner animation="border" size="sm" />:
  'Submit'}</Button>
              </Col>
              <Col id="Homejbs13">
                <iframe
                  id="Homejbs10"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.736109006811!2d77.75243377678838!3d13.003183381503064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0fbe30b32b01%3A0x61705129601b444e!2sJBS%20Interiors!5e1!3m2!1sen!2sin!4v1691045974567!5m2!1sen!2sin"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
              <Col
                md={{ span: "4", order: "last" }}
                xs={{ span: "12", order: "first" }}
              >
                <div id="Homejbs12" onClick={()=>setContactModal(true)}>
                  <div>Contact</div>
                  <div>Us</div>
                </div>
              </Col>
              <ContactUsModal show={contactModal} onHide={()=>setContactModal(false)} />
            </Row>
          </section>
        </Container>
      </>
    </>
  );
}
