import React, { useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import ServiceconstructionComp from '../../components/ServiceConstructionComp/ServiceconstructionComp'; 
import image0 from "../../assets/service.png";
import image1 from "../../assets/Service/Office1.jpg"
import image2 from "../../assets/Project/int2.jpg";
import image3 from "../../assets/Project/out1.jpg";
import image4 from "../../assets/service.png";
import image5 from "../../assets/Project/res2.jpg";
import image6 from "../../assets/Project/int1.jpg";
import image7 from "../../assets/service.png";
import image8 from "../../assets/Project/out6.jfif";
import image9 from "../../assets/Project/out7.jfif";
import image10 from "../../assets/service.png";
import image11 from "../../assets/Project/out3.jpg";
import image12 from "../../assets/interior1.jpg";
import image13 from "../../assets/interior2.webp"


function Interiors() {

    const data =[
        {image:image5},
        {image:image12},
        {image:image13},
        {image:image8},
        {image:image3},
        {image:image6},
        {image:image1},
        {image:image2},
        {image:image9},
        {image:image11},
    ]
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>

    <Container fluid >
        <Row>
          <Col md={12} xs={12}id="service5">
            <img src={image0} id="service1" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col id="service2">Interiors-Commercial</Col>
        </Row>
        <ServiceconstructionComp
          Interiors={data}
          text="Welcome to a world where design meets functionality, and spaces are transformed into vibrant hubs of creativity and productivity. Our passion for crafting exceptional commercial interiors is evident in every detail, from the strategic layout to the curated aesthetics. Discover how our expertise can breathe life into your commercial space and drive your business forward.Our design philosophy centers around merging aesthetics with practicality. We meticulously select materials, colors, and textures to create an environment that resonates with your brand ethos. From sleek modern offices to cozy retail boutiques, our designs are versatile, adaptable, and timeless."
        />
      </Container>
    
    
    </>
  )
}

export default Interiors