import { Col, Form, Row, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Utility from '../../Toast/Utility';
import { useState } from 'react';

export default function ContactUsModal(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [loader, setLoader] = useState(false);

    const form = useRef();
    const regName = /^[a-zA-Z ]+$/;
  const regEmail =
    /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;

    function Validation(){
        if(name.length === 0){
            Utility.eToast('Please Enter Your Name');
            return false;
        }else if(!regName.test(name)){
            Utility.eToast('Please Enter a Valid Name');
            return false;
        }else if(email.length === 0){
            Utility.eToast('Please Enter Your Email');
            return false;
        }else if(!regEmail.test(email)){
            Utility.eToast('Please Enter a Valid Email');
            return false;
        }else if(phone.length === 0 || phone.length !== 10){
          Utility.eToast('Please Enter a Valid Phone No.');
          return false;
        }
        else if(message.length === 0){
            Utility.eToast('Please Enter Your Message To Submit');
            return false;
        }else {
            return true;
        }
    }

  const sendEmail = () => {
    if(Validation()){
      setLoader(true);
      console.log('.................................',form.current)
    emailjs.sendForm('service_i6kr1za', 'template_ftktpft', form.current, 'hGE1kKL1GT2OvVykY')
      .then((result) => {
          Utility.sToast('Message Sent Successfully')
          setName('')
          setEmail('')
          setMessage('')
          setPhone('')
          setLoader(false);
          props.onHide()

      }, (error) => {
          Utility.eToast(error.text)
          setLoader(false);
      });
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ backgroundColor:'#da7962'}}>
        <Modal.Title id="contained-modal-title-vcenter"  style={{color:'white'}}>
         Contact Us
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:'2rem'}}>
             <Form ref={form} >
                  <Row>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name*"
                        id="Homejbs15"
                        name="user_name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Your Email*"
                        id="Homejbs16"
                        name="user_email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Phone No.*"
                        id="Homejbs16"
                        name="user_phone"
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="Homejbs17"
                        placeholder="Enter Your Message*"
                        name="message"
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}} md={4} xs={6}>
                <Button style={{width:'100%'}} id="Homejbs14" onClick={()=>sendEmail()}>{loader ? <Spinner animation="border" size="sm" />:
  'Submit'}</Button>
                </Col>
                <Col></Col>

                </Row>
                  
                </Form>
                
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

