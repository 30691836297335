import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import get from "../../assets/image 1 (1).png";
import map from "../../assets/image 27.png";
import "./Getintouch.css";
import emailjs from '@emailjs/browser';
import Utility from '../../Toast/Utility';

export default function Getintouch() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [loader, setLoader] = useState(false);

  const form = useRef();
  const regName = /^[a-zA-Z ]+$/;
const regEmail =
  /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;

  function Validation(){
      if(name.length === 0){
          Utility.eToast('Please Enter Your Name');
          return false;
      }else if(!regName.test(name)){
          Utility.eToast('Please Enter a Valid Name');
          return false;
      }else if(email.length === 0){
          Utility.eToast('Please Enter Your Email');
          return false;
      }else if(!regEmail.test(email)){
          Utility.eToast('Please Enter a Valid Email');
          return false;
      }else if(phone.length === 0 || phone.length !== 10){
        Utility.eToast('Please Enter a Valid Phone No.');
        return false;
      }
      else if(message.length === 0){
          Utility.eToast('Please Enter Your Message To Submit');
          return false;
      }else {
          return true;
      }
  }

  const sendEmail = () => {
    if(Validation()){
      setLoader(true);
      console.log('.................................',form.current)
    emailjs.sendForm('service_i6kr1za', 'template_ftktpft', form.current, 'hGE1kKL1GT2OvVykY')
      .then((result) => {
          Utility.sToast('Message Sent Successfully')
          setName('')
          setEmail('')
          setMessage('')
          setPhone('')
          setLoader(false);
      }, (error) => {
          Utility.eToast(error.text)
          setLoader(false);
      });
    }
  };
  return (
    <>
      <Container fluid >
        <Row>
          <Image src={get} alt="logo" id="fluid1" />
        </Row>
        <Row>
          <Image src={map} alt="logo" id="get1"  />
        </Row>
        <Container>
          <Row id="get3">
            <Col md={1} lg={1}></Col>
            <Col md={5} lg={5} sm={12} xs={12}>
              <Row id="get2">Our Office</Row>
              <Row id="get4">
                {" "}
                Site #42,Katermma Layout,Chansendra Kadugodi Bengaluru-567077
              </Row>
              <Row id="get7">Phone No.</Row>
              <Row id="get4">(+91) 8452325377</Row>
              <Row id="get7">Email Address</Row>
              <Row id="get4">info@mailstone.co.in</Row>
            </Col>
            <Col md={6} lg={6} sm={12} xs={12} id="get8">
            <Form ref={form} >
                <Row id="get6">
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Your Name*"
                      id="get20"
                      name="user_name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row id="get6">
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Your Email*"
                      id="get21"
                      name="user_email"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row id="get6">
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Your Phone No.*"
                      id="get21"
                      name="user_phone"
                      value={phone}
                      onChange={(e)=>setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row id="get6">
                  <FloatingLabel controlId="floatingTextarea2" label="Your Message*">
                    <Form.Control
                      id="get22"
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      name="message"
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                    />
                  </FloatingLabel>
                </Row>
              </Form>
              <Button id="get9" onClick={()=>sendEmail()}>{loader ? <Spinner animation="border" size="sm" />:
  'Submit'}</Button>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}