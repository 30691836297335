export const IMAGES = {
    allImages:[
        {image:require('../assets/AllService/image55.jpeg')},
        {image:require('../assets/AllService/image56.jpeg')},
        // {image:require('../assets/AllService/image57.jpeg')},
        // {image:require('../assets/AllService/image58.jpeg')},
        {image:require('../assets/AllService/image1.jpeg')},
        {image:require('../assets/AllService/image2.jpeg')},
        // {image:require('../assets/AllService/image3.jpeg')},
        {image:require('../assets/AllService/image4.jpeg')},
        // {image:require('../assets/AllService/image5.jpeg')},
        {image:require('../assets/AllService/image6.jpeg')},
        {image:require('../assets/AllService/image7.jpeg')},
        {image:require('../assets/AllService/image8.jpeg')},
        // {image:require('../assets/AllService/image9.jpeg')},
        // {image:require('../assets/AllService/image10.jpeg')},
        // {image:require('../assets/AllService/image11.jpeg')},
        // {image:require('../assets/AllService/image12.jpeg')},
        // {image:require('../assets/AllService/image13.jpeg')},
        // {image:require('../assets/AllService/image14.jpeg')},
        // {image:require('../assets/AllService/image17.jpeg')},
        // {image:require('../assets/AllService/image18.jpeg')},
        // {image:require('../assets/AllService/image19.jpeg')},
        // {image:require('../assets/AllService/image20.jpeg')},
        // {image:require('../assets/AllService/image23.jpeg')},
        // {image:require('../assets/AllService/image24.jpeg')},
        // {image:require('../assets/AllService/image25.jpeg')},
        // {image:require('../assets/AllService/image26.jpeg')},
        // {image:require('../assets/AllService/image28.jpeg')},
        // {image:require('../assets/AllService/image29.jpeg')},
        // {image:require('../assets/AllService/image30.jpeg')},
        // {image:require('../assets/AllService/image31.jpeg')},
        // {image:require('../assets/AllService/image33.jpeg')},
        // {image:require('../assets/AllService/image34.jpeg')},
        // {image:require('../assets/AllService/image35.jpeg')},
        // {image:require('../assets/AllService/image36.jpeg')},
        // {image:require('../assets/AllService/image37.jpeg')},
        // {image:require('../assets/AllService/image38.jpeg')},
        // {image:require('../assets/AllService/image39.jpeg')},
        // {image:require('../assets/AllService/image40.jpeg')},
        // {image:require('../assets/AllService/image41.jpeg')},
        // {image:require('../assets/AllService/image42.jpeg')},
        // {image:require('../assets/AllService/image43.jpeg')},
        // {image:require('../assets/AllService/image44.jpeg')},
        // {image:require('../assets/AllService/image45.jpeg')},
        // {image:require('../assets/AllService/image46.jpeg')},
        // {image:require('../assets/AllService/image47.jpeg')},
        // {image:require('../assets/AllService/image48.jpeg')},
        // {image:require('../assets/AllService/image49.jpeg')},
        // {image:require('../assets/AllService/image50.jpeg')},
        // {image:require('../assets/AllService/image51.jpeg')},
        // {image:require('../assets/AllService/image52.jpeg')},
        // {image:require('../assets/AllService/image53.jpeg')},
        // {image:require('../assets/AllService/image54.jpeg')},
        {image:require('../assets/Project/decor1.jpg')},
        {image:require('../assets/Project/decor2.jpg')},
        {image:require('../assets/Project/decor3.jpg')},
        {image:require('../assets/Project/decor4.jpeg')},
    ],
    jbsImage: [
        // {image:require('../assets/AllService/image55.jpeg')},
        // {image:require('../assets/AllService/image56.jpeg')},
        // {image:require('../assets/AllService/image1.jpeg')},
        // {image:require('../assets/AllService/image2.jpeg')},
        // {image:require('../assets/AllService/image4.jpeg')},
        // {image:require('../assets/AllService/image6.jpeg')},
        // {image:require('../assets/AllService/image7.jpeg')},
        // {image:require('../assets/AllService/image8.jpeg')},
        // {image:require('../assets/AllService/image9.jpeg')},
        {image:require('../assets/AllService/image10.jpeg')},
        {image:require('../assets/AllService/image11.jpeg')},
        {image:require('../assets/AllService/image12.jpeg')},
        {image:require('../assets/AllService/image13.jpeg')},
        {image:require('../assets/AllService/image14.jpeg')},
        {image:require('../assets/AllService/image17.jpeg')},
        {image:require('../assets/AllService/image18.jpeg')},
        {image:require('../assets/AllService/image19.jpeg')},
        {image:require('../assets/AllService/image20.jpeg')},
        {image:require('../assets/AllService/image23.jpeg')},
        {image:require('../assets/AllService/image24.jpeg')},
        {image:require('../assets/AllService/image25.jpeg')},
        {image:require('../assets/AllService/image35.jpeg')},
        {image:require('../assets/AllService/image36.jpeg')},
        {image:require('../assets/AllService/image37.jpeg')},
        {image:require('../assets/AllService/image38.jpeg')},
        {image:require('../assets/AllService/image39.jpeg')},
        {image:require('../assets/AllService/image40.jpeg')},
        {image:require('../assets/AllService/image41.jpeg')},
        {image:require('../assets/AllService/image42.jpeg')},
        {image:require('../assets/AllService/image43.jpeg')},
        {image:require('../assets/AllService/image44.jpeg')},
        {image:require('../assets/AllService/image45.jpeg')},
        {image:require('../assets/AllService/image46.jpeg')},
        {image:require('../assets/AllService/image47.jpeg')},
        // {image:require('../assets/Project/decor1.jpg')},
        // {image:require('../assets/Project/decor2.jpg')},
        // {image:require('../assets/Project/decor3.jpg')},
        // {image:require('../assets/Project/decor4.jpeg')},
    ]
}