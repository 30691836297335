import React, { useEffect } from "react";
import "./ServiceConstruction.css";
import { Container, Row, Col } from "react-bootstrap";
import image from "../../assets/service.png";
// import image1 from "../../assets/construction.png";
import image1 from "../../assets/Service/image83.jpeg";
import Header from "../../components/Header/Header";
import ServiceconstructionComp from "../../components/ServiceConstructionComp/ServiceconstructionComp";
import image2 from "../../assets/AllService/image84.jpeg";
import image3 from "../../assets/AllService/image3.jpeg";
import arch1 from "../../assets/Project/arch1.jpg";
import arch2 from "../../assets/Project/arch2.jpg";
import arch3 from "../../assets/Project/arch3.jpg";
import arch4 from "../../assets/Project/arch4.jpg";
import arch5 from "../../assets/Project/arch5.jpg";


export default function ServiceConstruction() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>
    {/* <Header /> */}
      <Container fluid >
        <Row>
          <Col md={12} xs={12}id="service5">
            <img src={image} id="service1" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col id="service2">Services - Construction - Commercial</Col>
        </Row>
        <ServiceconstructionComp
          img={arch1}
          img2={arch2}
          img3={arch3}
          text="Our focus primarily has been our residential portfolio ,however we have forayed into commercial segment recently after few successful ventures We are pro-actively exploring every opportunity in the Retail segments, Office spaces, Warehousing and Co-working spaces, restaurants, Malls, etc."
        />
      </Container>
    </>
  );
}
